import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
  , Box, Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import SearchLoadingImg from '../../../images/loading.gif';
import Pagination from '@mui/material/Pagination';
import axiosBackendApi from '../../../shared/services/api/axiosBackendInstance'; // Import loading image

// Define the interface for the URL parameters
interface Params {
  activityId: string; // Adjust this type based on your actual parameter type
}

const ActivityLogView: React.FC = () => {
  const SEARCH_URL = process.env.REACT_APP_WEB_API;
  const { activityId } = useParams<Params>();
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).token;
  const [count, setCountPage] = React.useState(1);
  const [sessionData, setSessionData] = useState<any>(null); // You can define a more specific type if known
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const fetchData = async () => {
    try {
      const response = await axiosBackendApi.get(`${SEARCH_URL}/user-sessions/${activityId}`,{
        params: {
          page: currentPage,
          per_page: 10,
        },
        headers: {
          Authorization: 'Bearer ' + token,
        }
      });
      if (response.data.code === 200) {
        setSessionData(response.data.data.items);
        console.log(response.data.data.items[0].user.name);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activityId]); // Added activityId as a dependency for useEffect
  const getPaginatedData = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    fetchData();
  };

  if (loading) {
    return  <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="400px">
      <img src={SearchLoadingImg} className="loading-img" alt="Loading" />
    </Box>; // Display loading image
  }

  if (!sessionData) {
    return <Typography>No data available</Typography>; // Handle case if sessionData is null
  }

  return (
     <>
       <TableContainer component={Paper} sx={{marginTop:15}}>
         <Typography variant="h6" component="div" style={{ padding: '16px' }}>
            { sessionData[0]?.user?.name || ' '}
         </Typography>
         <Table>
           <TableHead>
             <TableRow>
               <TableCell>ID</TableCell>
               <TableCell>Module</TableCell>
               <TableCell>Questions Count</TableCell>
               <TableCell>Created At</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {sessionData && sessionData.length > 0 ? (
               sessionData.map((activity: any) => (
                 <TableRow key={activity.id}>
                   <TableCell>{activity.id}</TableCell>
                   <TableCell>{activity.module}</TableCell>
                   <TableCell>{activity.questions_count}</TableCell>
                   <TableCell>{new Date(activity.created_at).toLocaleString()}</TableCell>
                 </TableRow>
               ))
             ) : (
               <TableRow>
                 <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                   No activities available.
                 </TableCell>
               </TableRow>
             )}
           </TableBody>
         </Table>
       </TableContainer>
       <Box
         sx={{
           display: 'flex',
           justifyContent: 'start',
           alignItems: 'center',
           pt: 3,
         }}
         className="pagination-wrapper"
       >
         <Pagination
           variant="outlined"
           count={count}
           page={currentPage}
           onChange={getPaginatedData}
         />
       </Box>
     </>

  );
};

export default ActivityLogView;

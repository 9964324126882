import { createAsyncThunk, createSlice, createAction  } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
const CHAT_URL = process.env.REACT_APP_WEB_API;
// Action Types
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_ERROR = 'FETCH_SESSION_ERROR';
export const UPDATE_SESSION_TITLE_SUCCESS = 'UPDATE_SESSION_TITLE_SUCCESS';
export const UPDATE_SESSION_TITLE_ERROR = 'UPDATE_SESSION_TITLE_ERROR';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_ERROR = 'DELETE_SESSION_ERROR';
// Fetch a session by ID
export const clearSession = createAction('sessions/clearSession');
export const newLog = createAction('sessions/newLog');
export const newRegenerateLog = createAction('sessions/newRegenerateLog');
export const patchAnswer = createAction('sessions/patchAnswer');
export const patchLog = createAction('sessions/patchLog');
export const setLogId = createAction('sessions/setLogId');


export const newQuestion = createAction('sessions/newQuestion');

export const fetchSessionById = createAsyncThunk(
  'sessions/fetchById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosBackendApi.get(`${CHAT_URL}/user/session/${id}`);
      return response.data.session; // directly return the session data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : 'Unknown error');
    }
  }
);
export const createSession = createAsyncThunk(
  'sessions/create',
  async (newSessionData, { rejectWithValue }) => {
    try {
      const response = await axiosBackendApi.post(`${CHAT_URL}/user/session`, newSessionData);
      return response.data.session;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : 'Unknown error');
    }
  }
);

export const fetchSessions = createAsyncThunk(
  'sessions/fetchSessions',
  async () => {
    const response = await axiosBackendApi.get(`${CHAT_URL}/user/session`);

    return response.data?.session;
  }
);
// @ts-nocheck
// export const updateSessionTitle = createAsyncThunk(
//   'sessions/updateTitle',
//   async ({ id, title }, { rejectWithValue }) => {
//     try {
//       await axios.post(`${CHAT_URL}/user/session/${id}`, {
//         title: title,
//         _method: 'patch',
//       });
//       return { id, title }; // return an object with id and title
//     } catch (error) {
//       return rejectWithValue(error.response ? error.response.data : 'Unknown error');
//     }
//   }
// );
export const deleteSession = createAsyncThunk(
  'sessions/delete',
  async (id, { rejectWithValue }) => {
    try {
      await axiosBackendApi.delete(`${CHAT_URL}/user/session/${id}`);
      return id;
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response ? error.response.data : 'Unknown error');
    }
  }
);

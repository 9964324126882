export default function Reload() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.52262 5.6418L12.95 5.5942L12.8965 1.16379M12.346 5.6061C12.0257 4.40851 11.3267 3.34664 10.3532 2.57903C9.37975 1.81142 8.18414 1.37925 6.94487 1.34704C5.7056 1.31483 4.48915 1.68432 3.47714 2.40032C2.46513 3.11633 1.71185 4.14046 1.32976 5.31979C0.947669 6.49913 0.95727 7.77042 1.35713 8.94385C1.75698 10.1173 2.52565 11.1299 3.54836 11.8306C4.57106 12.5312 5.79295 12.8823 7.0316 12.8313C8.27024 12.7804 9.45919 12.3302 10.4209 11.548"
        stroke="currentColor"
        strokeWidth="1.1375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

// @ts-nocheck
// @ts-ignore
import React, { Fragment, ReactNode } from 'react';
import '../theme/styled.d';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FormattedMessage, IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE, translationMessages } from '../i18n';
import { GlobalStyle } from '../theme/global';
import { ResponsiveThemeProvider } from '../shared/components/responsiveThemeProvider';
import { ToastRenderer } from '../shared/components/toast';
import { useStartup } from './useStartup';
import { useLanguage } from '../modules/lang/lang.context';

export interface AppComponentProps {
  children?: ReactNode;
}

export const AppComponent = ({ children }: AppComponentProps) => {
  useStartup();
  const { locale } = useLanguage();

  const language = DEFAULT_LOCALE;

  if (!language) {
    return null;
  }

  return (
    <IntlProvider key={language} locale={locale} messages={translationMessages[locale]}>
      <HelmetProvider>
        <Fragment>
          <FormattedMessage   id="app.pageTitle"  defaultMessage="Data Managment Office" description="App / Page title">
            {([pageTitle]: [string]) => <Helmet titleTemplate={`%s - ${pageTitle}`} defaultTitle={pageTitle} />}
          </FormattedMessage>
          <GlobalStyle />
          <ResponsiveThemeProvider>{React.Children.only(children)}</ResponsiveThemeProvider>
          {/* <ToastRenderer /> */}
        </Fragment>
      </HelmetProvider>
    </IntlProvider>
  );
};

// ButtonComponent.js

import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/joy/Button';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
const ButtonComponent = ({ file, tocError, openSummary, messages, index, summaryLoading }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (tocError) {
      setLoading(true); // Show loader on retry click
      openSummary(file.name, index);
      // console.log(file.name, index);
    } else {
      openSummary(file.name, index);
      // console.log(file.name, index);
    }
  };

  useEffect(() => {
    // Reset loading if there is still an error
    if (tocError) {
      setLoading(false);
    }
  }, [tocError]);

  return (
    <Button
      disabled={false}
      onClick={handleClick}
      className='text-link summary'
      title={tocError ? "Retry fetching TOC" : "Show Summary"}
      sx={{ color: 'rgba(135, 142, 165, 0.75)' }}
    >
      {loading ? <>
        <div className="loader-container">
          <CircularProgress className={'spinner_button'} />
        </div>
        <SummarizeOutlinedIcon/></> : tocError ? 'Retry' : <SummarizeOutlinedIcon/>}
      {file.toc == null && !tocError && <CircularProgress color="success" />}
    </Button>
  );
};

export default ButtonComponent;

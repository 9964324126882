import React, { useEffect, useState } from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const SelectModel = ({ placeholder, variant, className, sx, options, onSelectModel }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const [value, setValue] = useState(() => {
    let model = localStorage.getItem('selectedModel') || (type === 'live' ? 'chatRAGOnline' : 'chatLLM');
    onSelectModel(model);
    return model;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onSelectModel(newValue);
    localStorage.setItem('selectedModel', newValue);
  };
  return (
    <Select
      value={value}
      className={className}
      variant={variant}
      sx={{
        ...sx,
        '.MuiSelect-select': {
          transition: 'all 0.7s ease',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 'bold',
        },
        '&:hover': {
          '.MuiSelect-select': {
            backgroundColor: '#a21e1e',
          },
        },
        '.MuiSelect-menu': {
          border: '1px solid #ccc',
          boxShadow: '0 4px 8px rgba(0,0,0,0.9)',
        },
      }}
      onChange={handleChange}
    >
      {options.map((option, index) => (
        <Option key={index} value={option.value} sx={{ borderRadius: 2 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={option.label} placement="right" sx={{ color: "#fff" }}>
              <span style={{ marginLeft: '10px' }}>{option.label}</span>
            </Tooltip>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default SelectModel;

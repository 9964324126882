// @ts-nocheck
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { AudioRecorder,  } from 'react-audio-voice-recorder';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export default function ChatAudioRecorder({ handleAddAudio }) {
  const [url, setUrl] = useState('');


  const addAudioElement = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    // const url = "/melody-of-nature-main(chosic.com).mp3"
    setUrl(url);

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      handleAddAudio(base64data);
    };
  };


  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AudioRecorder
        onRecordingComplete={addAudioElement}
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
          // autoGainControl,
          // channelCount,
          // deviceId,
          // groupId,
          // sampleRate,
          // sampleSize,
        }}
        onNotAllowedOrFound={(err) => console.table(err)}
        // downloadOnSavePress={true}
        downloadFileExtension="webm"
        mediaRecorderOptions={{
          audioBitsPerSecond: 128000,
        }}
        showVisualizer={true}
      />
      {url && <audio src={url}  controls />}
      {url && (
        <div className="dark-mode-color">
          <IconButton onClick={() => setUrl(null)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default function TranslateIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.91675 4.66602L5.83341 7.58268M2.33341 8.16602L5.83341 4.66602L7.00008 2.91602M1.16675 2.91602H8.16675M4.08341 1.16602H4.66675M7.53269 9.91602H11.7175M7.53269 9.91602L6.41675 12.2493M7.53269 9.91602L9.20409 6.42128C9.33877 6.13967 9.40611 5.99887 9.49825 5.95438C9.57838 5.91568 9.67179 5.91568 9.75191 5.95438C9.84406 5.99887 9.9114 6.13967 10.0461 6.42128L11.7175 9.91602M11.7175 9.91602L12.8334 12.2493"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

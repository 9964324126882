// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Button, IconButton,
} from '@mui/material';
import Textarea from "@mui/joy/Textarea";
import sendIcon from '../../images/sendArrow.svg';
import { useState } from "react";
import newsletterImage from "../../images/icons/envelope.svg"
import reload from '../../images/Reload.svg';
import copy from '../../images/copy.svg';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import dots from '../../images/Dots.svg';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { NewsletterAnswer } from "../newsLetter";
import SearchLoadingImg from '../../images/loading.gif';
import { useAdminPanelContext } from '../../shared/components/adminPanel/adminPanelContext';

const CHAT_URL = process.env.REACT_APP_CHAT_API;

export const NewsletterChat = ({ document, type }) => {
  const { logout } = useAdminPanelContext();
  const [question, setQuestion] = useState('');
  const [chat, setChat] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const localData = localStorage.getItem('user');
  const userData = localData ? JSON.parse(localData) : null;
  const token = userData?.token;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [chat]);

  const send = async () => {
    if (!question.trim()) return;
    // Add the user's question to the chat array
    setChat((prevChat) => [
      ...prevChat,
      {
        type: 'question',
        message: question,
      }

    ]);
    setQuestion("")

    let payload = {
      user_id: userData.id,
      query: question,
      ...(type !== 'search' ? { news_id: document.id } : { _id: document._id })
    };
    const url = type === 'search' ? `${CHAT_URL}/search_rag` : `${CHAT_URL}/newsletter_rag`;

    setIsLoading(true);
    setIsStreaming(true);
    let answer = ''; // To accumulate the streaming response

    try {
      const myResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
        signal: new AbortController().signal,
      });
      const reader = myResponse.body.getReader();
      let chunks = '';
      reader.read().then(function processText({ done, value }) {
        let chunk = new TextDecoder('utf-8').decode(value);
        if (done) {
          setIsStreaming(false);
          return;
        }
        chunks += chunk;
        setChat((prevChat) => {
          1
          const updatedChat = [...prevChat];
          const lastMessage = updatedChat[updatedChat.length - 1];
          if (lastMessage && lastMessage.type === 'answer') {
            lastMessage.message = chunks;
          } else {
            updatedChat.push({ type: 'answer', message: chunks });
          }
          return updatedChat;
        });

        return reader.read().then(processText);
      });
      if (myResponse.status === 401){
        logout()
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsStreaming(false);
      setIsLoading(false);
    }
  };
  let user = JSON.parse(localStorage.getItem('user')).name;
  // const generate = (message) => {

  // }
  return (
      <Box sx={{ minHeight: '60vh', position: 'relative'}} className="t p-0 ">
      <div style={{ maxHeight: '40vh', overflowY: 'auto' }} className={'hide-scroll'}>
        {chat.map((message, i) => (
          <div key={i} className="chat-newsletter-app">
            <Box sx={{ padding: "0 !important", border: '2px solid  !important', boxShadow: 'none !important', borderRadius: 8 }}>
              <CardContent sx={{ boxShadow: message.type != 'question' ? 2 : 0, border: 0, borderRadius: 6 }} >
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: 16, color: "#919191" }}>
                  {message.type === 'question' ? user : <div><img src={newsletterImage} alt={'reg'} style={{ marginInline: 5 }} />
                    {type === "search" ? ("MOD Local") : ("MOD NewsLetter")}
                  </div>}
                </Typography>
                <NewsletterAnswer message={message} />
              </CardContent>
            </Box>
          </div>
        ))}
        {
          isLoading && (
            <div className="loading" style={{ width: '100px' }}>
              <div className="loading__typing"></div>
            </div>
          )
        }
        <div ref={messagesEndRef} />
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'end',
        border: 1,
        borderRadius: '10px',
        boxShadow: '0 0 2px #ccc',
        padding: '10px',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
      }}  className={'modal_textarea_box'}>
        <Textarea
          minRows={2}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              if (!question.trim()) return;
              if (isStreaming) return;
              if (isLoading) return;
              e.preventDefault();
              send();
            }
          }}
          variant="plain"
          className={'modal_textarea'}
          sx={{
            width: '95%',
            backgroundColor: 'transparent',
            padding: 1,
            color: "#a1a1a1",
            '& hover': {
              color: "#a1a1a1",
            },
            border: 'none !important',  // Removes the border
            outline: 'none !important',  // Removes the outline
            '&.Mui-focused': {
              border: 'none !important',  // Ensures no border is shown on focus
              outline: 'none !important'  // Ensures no outline is shown on focus
            }
          }}
          placeholder="Write Some Thing  here ..."
        />
        <Tooltip title="Send Message" placement="right">
          <Button
            onClick={send}
            disabled={!question.trim() || isStreaming}
            sx={{
              display: 'flex',
              borderRadius: '10%',
              backgroundColor: '#29504d',
              textAlign: 'end',
              width: "80px !important",
              height: '40px !important',
              '&:hover': {
                backgroundColor: 'rgba(8,89,80,0.69)',
              },
            }}
          >
            <img src={sendIcon} className="send" alt="Send" />
          </Button>
        </Tooltip>
      </div>
    </Box>
  );
}



export default function Copy() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.48626 9.66723C1.69306 9.66723 1.05005 9.02422 1.05005 8.23102V2.69137C1.05005 1.78486 1.78492 1.04999 2.69143 1.04999H8.23108C9.02428 1.04999 9.66729 1.693 9.66729 2.48619"
        stroke="currentColor"
        strokeWidth="1.1375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3086 4.33276H5.97414C5.06763 4.33276 4.33276 5.06763 4.33276 5.97414V11.3086C4.33276 12.2151 5.06763 12.95 5.97414 12.95H11.3086C12.2151 12.95 12.95 12.2151 12.95 11.3086V5.97414C12.95 5.06763 12.2151 4.33276 11.3086 4.33276Z"
        stroke="currentColor"
        strokeWidth="1.1375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

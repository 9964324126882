export function ThumbsUpOutlined() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_907_21526)">
        <path
          d="M4.08341 12.8327V6.41602M1.16675 7.58268V11.666C1.16675 12.3103 1.68908 12.8327 2.33341 12.8327H10.1654C11.0291 12.8327 11.7637 12.2025 11.895 11.3488L12.5232 7.26545C12.6863 6.20537 11.8661 5.24935 10.7936 5.24935H8.75008C8.42792 5.24935 8.16675 4.98818 8.16675 4.66602V2.60442C8.16675 1.81001 7.52275 1.16602 6.72834 1.16602C6.53886 1.16602 6.36715 1.2776 6.2902 1.45075L4.23738 6.0696C4.14375 6.28025 3.93485 6.41602 3.70432 6.41602H2.33341C1.68908 6.41602 1.16675 6.93835 1.16675 7.58268Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_907_21526">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ThumbsUpSolid() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_907_21526)">
        <path
          d="M4.08341 12.8327V6.41602M1.16675 7.58268V11.666C1.16675 12.3103 1.68908 12.8327 2.33341 12.8327H10.1654C11.0291 12.8327 11.7637 12.2025 11.895 11.3488L12.5232 7.26545C12.6863 6.20537 11.8661 5.24935 10.7936 5.24935H8.75008C8.42792 5.24935 8.16675 4.98818 8.16675 4.66602V2.60442C8.16675 1.81001 7.52275 1.16602 6.72834 1.16602C6.53886 1.16602 6.36715 1.2776 6.2902 1.45075L4.23738 6.0696C4.14375 6.28025 3.93485 6.41602 3.70432 6.41602H2.33341C1.68908 6.41602 1.16675 6.93835 1.16675 7.58268Z"
          stroke="none"
          fill="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_907_21526">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

// @ts-nocheck

import { useEffect, useReducer, useRef, useState } from 'react';

import { TitleTagSize } from '../../shared/components/titleTag/titleTag.types';

// import {Container, TitleTag} from './newsLetter.styles';

import * as React from 'react';
import axios from 'axios';
import { Alert, Button, ButtonGroup, CircularProgress, Grid, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import Link from '@mui/material/Link';
import { ROUTES } from '../app.constants';
import { prop } from 'ramda';
import Snackbar from '@mui/material/Snackbar';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import {DeleteOutline as DeleteOutlineIcon} from "@mui/icons-material";

const SEARCH_URL = process.env.REACT_APP_WEB_API;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type newsLetterProps = {
  id: number;
};
const allDomains = ['Technology', 'Business', 'Science', 'Defence & Security', 'Strategy', 'Geopolitical'];
const DomainsWithSub = [
  {
    name: 'Technology',
    fields: [
      'Artificial Intelligence',
      'Cybersecurity',
      'Data Science',
      'Internet of Things (IoT)',
      'Hardware',
      'Augmented Reality (AR)',
      'Virtual Reality (VR)',
      'Cloud Computing',
      'Robotics',
      'Quantum Computing',
    ],
  },
  {
    name: 'Business',
    fields: [
      'Finance',
      'Marketing',
      'Entrepreneurship',
      'Management',
      'Human Resources',
      'Supply Chain Management',
      'E-commerce',
      'Strategic Planning',
      'Business Analytics',
      'Corporate Social Responsibility (CSR)',
    ],
  },
  {
    name: 'Science',
    fields: [
      'Biology',
      'Chemistry',
      'Physics',
      'Environmental Science',
      'Astronomy',
      'Neuroscience',
      'Genetics',
      'Geology',
      'Climate Science',
      'Materials Science',
    ],
  },
  {
    name: 'Defence & Security',
    fields: [
      'Military Strategy',
      'Cyber Warfare',
      'Ballistic Missiles',
      'Nuclear Deterrence',
      'Counterterrorism',
      'Military Technology',
      'Intelligence Analysis',
      'Defense Policy',
      'Homeland Security',
      'Special Operations',
    ],
  },
  {
    name: 'Strategy',
    fields: [
      'Business Strategy',
      'Military Strategy',
      'Marketing Strategy',
      'Competitive Strategy',
      'Digital Strategy',
      'Innovation Strategy',
      'Corporate Strategy',
      'Risk Management',
      'Brand Strategy',
      'Supply Chain Strategy',
    ],
  },
  {
    name: 'Geopolitical',
    fields: [
      'International Relations',
      'Geopolitical Risk Analysis',
      'Diplomacy',
      'Globalization',
      'Security Studies',
      'Geopolitical Economy',
      'Foreign Policy Analysis',
      'Regional Studies',
      'Conflict Resolution',
      'Terrorism Studies',
    ],
  },
];
export const EditNewsLetter = (props) => {
  const [email, setEmail] = React.useState('');
  const [domains, setDomains] = React.useState<string[]>([]);
  const [subDomains, setSubDomains] = React.useState([]);
  const [selectedSubDomains, setSelectedSubDomains] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof domains>) => {
    const {
      target: { value },
    } = event;
    setDomains(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    const selected = event.target.value;
    var subSelected: any[] | ((prevState: string[]) => string[]) = [];
    for (let index = 0; index < selected.length; index++) {
      for (let sub = 0; sub < DomainsWithSub.length; sub++) {
        if (DomainsWithSub[sub].name == selected[index]) {
          subSelected.push(...DomainsWithSub[sub].fields);
          setSelectedSubDomains([]);
        }
      }
    }
    setSubDomains(subSelected);
  };
  const handleSubDomainsChange = (event: SelectChangeEvent<typeof domains>) => {
    const {
      target: { value },
    } = event;
    setSelectedSubDomains(typeof value === 'string' ? value.split(',') : value);
  };

  const [topics, setTopics] = React.useState<string[]>(['']);
  const addTopic = () => {
    setTopics((prevMessages) => [...prevMessages, '']);
  };
  const removeTopic = (index: number) => {
    setTopics([...topics.slice(0, index), ...topics.slice(index + 1)]);
  };

  const [sources, setSources] = React.useState<string[]>(['']);
  const addSource = () => {
    setSources((prevMessages) => [...prevMessages, '']);
  };
  const removeSource = (index: number) => {
    setSources([...sources.slice(0, index), ...sources.slice(index + 1)]);
  };

  const [frequently, setFrequently] = React.useState('');
  const [frequentlyMain, setFrequentlyMain] = React.useState('');
  const [frequentlyDay, setFrequentlyDay] = React.useState('');
  const [frequentlyTime, setFrequentlyTime] = React.useState('');
  const [frequentlyDayNum, setFrequentlyDayNum] = React.useState<number | null>(null);

  const handleFrequently = (event: SelectChangeEvent) => {
    setFrequentlyMain(event.target.value as string);
    if (event.target.value !== 'Weekly') {
      setFrequently(event.target.value as string);
    } else {
      // setFrequently('')
      setFrequently('Saturday');
      setFrequentlyDay('Saturday');
    }
    if (event.target.value != 'Monthly') {
      setFrequentlyDayNum(null);
    }
  };
  const selectFrequentlyDay = (event: SelectChangeEvent) => {
    setFrequentlyDay(event.target.value as string);
    setFrequently(event.target.value as string);
  };

  const [showDay, setShowDay] = React.useState(false);
  const selectDay = (e) => {
    e.preventDefault();
    setShowDay(!showDay);
  };
  const handleSelectDay = (day) => {
    setFrequentlyDayNum(day);
    setShowDay(false);
  };

  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const handleUpdate = () => {
    var filteredTopics = topics.filter(function (el) {
      return el?.trim().length != 0 && el != null;
    });
    var filteredSources = sources.filter(function (el) {
      return el?.trim().length != 0 && el != null;
    });
    const user = localStorage.getItem('user');
    const token = JSON.parse(user).token;
    setLoading(true);
    axiosBackendApi
      .put(
        SEARCH_URL + '/newsletters/' + props.newsLetterId,
        {
          email: email,
          domains: domains,
          sub_domains: selectedSubDomains,
          sources: filteredSources,
          frequently: frequently,
          frequently_time: frequentlyTime,
          frequently_day_num: frequentlyDayNum,
          status: true,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        // handleClickSendMessage(response.data.text);
        setLoading(false);
        handleClickSuccess();
        setTimeout(() => {
          props.onEditClick();
        }, 500);
      })
      .catch((error) => {
        if (error.response?.status == 422) {
          setErrors(error.response?.data?.errors ?? {});
        }
        setLoading(false);
        handleClickFailed();
      });
  };

  // success alert
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleClickSuccess = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccess = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  // Failed alert
  const [openFailed, setOpenFailed] = React.useState(false);
  const handleClickFailed = () => {
    setOpenFailed(true);
  };
  const handleCloseFailed = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailed(false);
  };
  const handleOutsideClick = (e) => {
    if (e.target.className !== 'frequentlyDayNumSpan' && e.target.id !== 'dat-time') {
      setShowDay(false);
    }
  };
  const didUnmount = useRef(false);
  useEffect(() => {
    const user = localStorage.getItem('user');
    const userEmail = JSON.parse(user).email;
    const token = JSON.parse(user).token;

    axiosBackendApi
      .get(`${SEARCH_URL}/newsletters/${props.newsLetterId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data?.data?.data;
          if (data.email?.length) {
            setEmail(data.email);
          } else {
            setEmail(userEmail);
          }
          setDomains(data.domains || []);
          setSelectedSubDomains(data.sub_domains || []);
          setSources(data.sources || []);
          setFrequently(data.frequently || '');
          setFrequentlyMain(data.frequently !== 'Daily' && data.frequently !== 'Monthly' ? 'Weekly' : data.frequently);
          setFrequentlyDay(data.frequently !== 'Daily' && data.frequently !== 'Monthly' ? data.frequently : '');
          setFrequentlyDayNum(data.frequently_day_num || null);
          setFrequentlyTime(data.frequently_time || '');
        }
      })
      .catch((error) => {
        console.log(error);
      });

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      didUnmount.current = true;
    };
  }, [props.newsLetterId]);

  return (
    <div className="custom-form">
      <FormControl sx={{ width: '100%' }}>
        <label htmlFor="email" className="mb-1 d-block">
          {props.messages.Email} <span className="text-danger text-bold">*</span>
        </label>
        <OutlinedInput id={email} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        {errors.email ? <small className="text-danger">{errors.email}</small> : ''}
      </FormControl>
      <FormControl sx={{ width: '100%' }} className="mt-2">
        <label htmlFor="email" className="mb-1 d-block">
          {props.messages.DomainsFields} <span className="text-danger text-bold">*</span>
        </label>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={domains}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Domains / Fields" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {allDomains.map((name, index) => (
            <MenuItem key={index} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {errors.domains ? <small className="text-danger">{errors.domains}</small> : ''}
      </FormControl>
      {domains.length ? (
        <FormControl sx={{ width: '100%' }} className="mt-2">
          <label htmlFor="email" className="mb-1 d-block">
            {props.messages.SubDomains}
            <span className="text-danger text-bold">*</span>
          </label>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectedSubDomains}
            onChange={handleSubDomainsChange}
            input={<OutlinedInput id="select-multiple-chip" label="Domains / Fields" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {subDomains.map((name, index) => (
              <MenuItem key={index} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {errors.sub_domains ? <small className="text-danger">{errors.sub_domains}</small> : ''}
        </FormControl>
      ) : (
        ''
      )}
      {sources.map((source, index) => (
        <div className="multi-input mt-2 items-end gap-2" key={index}>
          <FormControl sx={{ width: '100%' }}>
            {!index ? (
              <label htmlFor="email" className="mb-1 d-block">
                {props.messages.SpecificSources}
              </label>
            ) : (
              ''
            )}
            <OutlinedInput
              id={source}
              type="text"
              value={source}
              placeholder=""
              onChange={(e) => {
                const ev = e.target.value;
                setSources((prevArr) => {
                  const result = [...prevArr];
                  result[index] = ev;
                  return result;
                });
              }}
            />
          </FormControl>
          <div className="flex items-end gap-2">
            <IconButton color="primary" className={`${index === 0 ? 'first' : ''} icon-outline`} onClick={addSource}>
              <AddIcon />
            </IconButton>
            {index >= 1 && (
              <IconButton
                color="primary"
                className={`${index === 0 ? 'first delete' : ''} icon-outline`}
                onClick={() => removeSource(index)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}
      <FormControl className="mt-2" fullWidth>
        <label className="mb-1 d-block">
          {props.messages.Scheduling} <span className="text-danger text-bold">*</span>
        </label>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={frequentlyMain}
          onChange={handleFrequently}
        >
          <MenuItem value={'Daily'}>{props.messages.Daily}</MenuItem>
          <MenuItem value={'Weekly'}>{props.messages.Weekly}</MenuItem>
          <MenuItem value={'Monthly'}>{props.messages.Monthly}</MenuItem>
        </Select>
      </FormControl>
      {frequentlyMain === 'Weekly' ? (
        <FormControl fullWidth>
          <label className="mb-1 mt-1 d-block">
            {props.messages.FrequentlyDay} <span className="text-danger text-bold">*</span>
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={frequentlyDay}
            onChange={selectFrequentlyDay}
          >
            {['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map(day => (
              <MenuItem key={day} value={day}>
                {props.messages[day]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      {errors.frequently ? <small className="text-danger">{errors.frequently}</small> : ''}
      {frequentlyMain == 'Monthly' ? (
        <FormControl className="mt-2" fullWidth>
          <label className="mb-1 d-block">
            {props.messages.Day} <span className="text-danger text-bold">*</span>
          </label>
          <OutlinedInput
            id="dat-time"
            type="text"
            value={frequentlyDayNum}
            onClick={(e) => {
              selectDay(e);
            }}
          />
          <div className={showDay ? 'frequentlyDayNum show' : 'frequentlyDayNum'}>
            {[...Array(31)].map((e, i) => {
              return (
                <span
                  key={i}
                  onClick={() => handleSelectDay(i + 1)}
                  className={frequentlyDayNum === i + 1 ? 'selected frequentlyDayNumSpan' : 'frequentlyDayNumSpan'}
                >
                  {i + 1}
                </span>
              );
            })}
          </div>
          {errors.frequently_day_num ? <small className="text-danger">{errors.frequently_day_num}</small> : ''}
        </FormControl>
      ) : (
        ''
      )}
      {frequentlyMain != '' ? (
        <FormControl className="mt-2" fullWidth>
          <label className="mb-1 d-block">
            {props.messages.Time} <span className="text-danger text-bold">*</span>
          </label>
          <OutlinedInput
            id="dat-time"
            type="time"
            value={frequentlyTime}
            onChange={(e) => {
              const time = e.target.value.split(":")[0];
              setFrequentlyTime(`${time}:00`);
            }}
          />
          {errors.frequently_time ? <small className="text-danger">{errors.frequently_time}</small> : ''}
        </FormControl>
      ) : (
        ''
      )}
      <div className="mt-2 pt-2">
        <Button
          variant="contained"
          color="success"
          className={loading ? 'cursor-not-allowed btn-default' : 'btn-default create-newsletter-btn'}
          onClick={handleUpdate}
        >
          {props.messages.Update}
        </Button>
      </div>
      <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
          className="alert-success"
        >
          {props.messages.UpdatedSuccessfully}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={2500} onClose={handleCloseFailed}>
        <Alert
          className="alert-error"
          onClose={handleCloseFailed}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {props.messages.FailedToUpdate}
        </Alert>
      </Snackbar>
    </div>
  );
};
